import React from "react";
import { Helmet } from "react-helmet";

function SiteSEO({ title, description, keywords, image }) {
  const defaultTitle = title;
  const pageDescription = description;
  const pageKeywords = keywords;
  const pageTitle = title;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
    >
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
      <meta property="image" content={image} />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:image" content={image} />
      <meta name="og:type" content="website" />
    </Helmet>
  );
}

export default SiteSEO;
